import {createRouter as createVueRouter, createWebHistory} from 'vue-router';

function createRoutes(ignored_app) {
    const routes = [
        {
            path: '/',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/home.vue'),
            }
        },
        {
            path: '/old_editions',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/editions.vue'),
            }
        },
        {
            path: '/editions',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/editions.vue'),
            }
        },
        {
            path: '/editions/edit',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/editEditions.vue'),
            }
        },
        {
            path: '/old_editions/edit',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/editEditions.vue'),
            }
        },
        {
            path: '/editions/articles',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/articles.vue'),
            }
        },
        {
            path: '/old_editions/articles',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/articles.vue'),
            }
        },
        {
            path: '/editions/articles/print',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/printArticles.vue'),
            }
        },
        {
            path: '/old_editions/articles/print',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/printArticles.vue'),
            }
        },
        {
            path: '/editions/articles/create',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/createArticle.vue'),
            }
        },
        {
            path: '/editions/articles/edit',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/editArticle.vue'),
            }
        },
        {
            path: '/advertisements',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/advertisements.vue'),
            }
        },
        {
            path: '/advertisements/edit',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/editAdvertisement.vue'),
            }
        },
        {
            name: 'forgotPassword',
            path: '/forgotPassword',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/forgotPassword.vue'),
            }
        },
        {
            name: 'createAccount',
            path: '/createAccount',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/register.vue'),
            }
        },
        {
            name: 'login',
            path: '/login',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/login.vue'),
            }
        },
        {
            name: 'help',
            path: '/help',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/helpFAQ.vue'),
            }
        },
        {
            name: 'settings',
            path: '/settings',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/settings.vue'),
            }
        },
        {
            path: '/settings/edit',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/editSetting.vue'),
            }
        },
        {
            name: 'myaccount',
            path: '/myaccount',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/myAccount.vue'),
            }
        },
        {
            name: 'users',
            path: '/users',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/users.vue'),
            }
        },
        {
            path: '/users/edit',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/editUser.vue'),
            }
        },
        {
            name: 'privacy',
            path: '/privacy',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/privacy.vue'),
            }
        },
        {
            name: 'imprint',
            path: '/imprint',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/imprint.vue'),
            }
        },
        {
            name: 'tutorial',
            path: '/tutorial',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/tutorial_preLogin.vue'),
            }
        },
    ];

    const route404 = {
        path: '/:catchAll(.*)',
        components: {
            default: () => import(/* webpackChunkName: "notFound" */ '../../components/views/notFound.vue'),
        }
    };

    // noinspection JSUnusedLocalSymbols
    routes.push(route404);

    return routes;
}

export default (app) => {
    return createVueRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes: createRoutes(app),
    })
}
