<template>
  <div class="main-menu">
    <router-link to="/">
      <div class="main-menu-logo" v-html-safe="getSVG('logo')" />
    </router-link>
    <div class="main-menu-links">
      <div class="main-menu-link-container" v-for="route in getUserPages">
        <router-link :to="'/'+(route.key === 'home' ? '' : route.key)"
                     :class="{ 'router-link-active': isRouteActive(route.key) }">
          <span class="logo" v-html-safe="getSVG(route.key)" />
          <span class="logo_active" v-html-safe="getSVG(route.key + '_active')" />
          <span class="name">{{ route.title }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      titles: {
        home: 'Home',
        editions: 'Ausgaben',
        old_editions: 'Vergangene Ausgaben',
        advertisements: 'Anzeigen',
        my_advertisements: 'Meine Anzeigen',
        users: 'Nutzer',
        myaccount: 'Mein Konto',
        settings: 'Einstellungen',
      },
      firstKnowledgeCategory: null,
    };
  },
  async mounted() {
    for(let link of document.getElementsByClassName("main-menu-link-container")) {
      link.addEventListener("mouseleave", (e) => {
        e.target.classList.add("removed-hover");
        setTimeout(() => {
          e.target.classList.remove("removed-hover");
        }, 500);
      });
    }
  },
  computed: {
    getUserPages() {
      const capabilities = this.$store.state?.user?.capabilities ?? [];

      var pages = [];

      if(capabilities.includes("advanced")) {
        pages = [
          {
            key: 'home',
            title: 'Home'
          },
          {
            key: 'editions',
            title: 'Ausgaben'
          },
          {
            key: 'old_editions',
            title: 'Alte Ausgaben'
          },
          {
            key: 'advertisements',
            title: 'Anzeigen'
          },
          {
            key: 'users',
            title: 'Nutzer'
          },
          {
            key: 'myaccount',
            title: 'Mein Konto'
          },
          {
            key: 'settings',
            title: 'Einstellungen'
          },
        ];
      } else if(capabilities.includes("editor")) {
        pages = [
          {
            key: 'home',
            title: 'Home'
          },
          {
            key: 'editions',
            title: 'Ausgaben'
          },
          {
            key: 'old_editions',
            title: 'Alte Ausgaben'
          },
          {
            key: 'advertisements',
            title: 'Anzeigen'
          },
          {
            key: 'myaccount',
            title: 'Mein Konto'
          },
          {
            key: 'settings',
            title: 'Einstellungen'
          },
        ];
      } else if(capabilities.includes("default")) {
        pages = [
          {
            key: 'home',
            title: 'Home'
          },
          {
            key: 'editions',
            title: 'Ausgaben'
          },
          {
            key: 'old_editions',
            title: 'Alte Ausgaben'
          },
          {
            key: 'advertisements',
            title: 'Meine Anzeigen'
          },
          {
            key: 'myaccount',
            title: 'Mein Konto'
          },
        ];
      }

      return pages;
    },
    getUserImage() {
      if((this.$store.state?.user?.image ?? null) !== null) {
        return 'background-image: url(' + this.$store.state.user.image + ');';
      }
      if(this.$store.state.lightMode) {
        return 'background-image: url(' + this.getImage('user_light.svg') + ');';
      }
      return 'background-image: url(' + this.getImage('user.svg') + ');';
    },
    getThemeSwitcher() {
      return !this.$store.state.lightMode ? 'sun' : 'moon';
    }
  },
  methods: {
    isRouteActive(routeKey) {
      return this.$route.fullPath.includes("/" + routeKey);
    }
  }
}
</script>
